/*** Vendors ***/
import LazyLoad from "react-lazyload";
import dynamic from "next/dynamic";
import Image from "next/image";
import styles from "./Index.module.scss";

/*** components ***/
import Banner from "./Banner";
import Title from "mobile/components/atoms/Title";
import Feedback from "mobile/components/organisms/Feedback";
import PostSlider from "mobile/components/organisms/PostSlider";
import ServiceCard from "mobile/components/molecules/ServiceCard";
import ServiceCatalog from "mobile/components/organisms/ServiceCatalog";
import GleadsSubjects from "mobile/components/organisms/GleadsSubjects";

import { AiOutlineRise } from "@react-icons/all-files/ai/AiOutlineRise";
import { FiMapPin } from "@react-icons/all-files/fi/FiMapPin";
import { FiBarChart } from "@react-icons/all-files/fi/FiBarChart";
import { BiBroadcast } from "@react-icons/all-files/bi/BiBroadcast";
import { BiNetworkChart } from "@react-icons/all-files/bi/BiNetworkChart";
import { BiBriefcaseAlt2 } from "@react-icons/all-files/bi/BiBriefcaseAlt2";
import { BiBuildingHouse } from "@react-icons/all-files/bi/BiBuildingHouse";
import { BiBuilding } from "@react-icons/all-files/bi/BiBuilding";

const CountUp = dynamic(
  () => import("mobile/components/templates/trang-chu/CountUp"),
  {
    ssr: false,
  }
);

/** data */

const campaigns = [
  {
    icon: <AiOutlineRise size={40} color="#2EAFEF" />,
    title: "Tăng trưởng traffic",
    description:
      "Lưu lượng truy cập thực (real traffic) được xem là nền tảng phát triển của mọi chiến dịch tiếp thị số. Gleads cung cấp các giải pháp tăng trưởng lưu lượng tự nhiên và tối ưu organic traffic giúp đảm bảo website của doanh nghiệp đạt hiệu suất hiệu suất tối ưu nhất, từ đó tăng khả năng cạnh tranh giúp doanh nghiệp làm chủ ngành hàng.",
  },
  {
    icon: <BiBroadcast size={40} color="#2EAFEF" />,
    title: "Tăng độ phủ sóng thương hiệu",
    description:
      "Phát triển nhận diện thương hiệu mang lại sức mạnh phi thường cho mọi doanh nghiệp. Website leo top 1 và duy trì ổn định trên sân chơi Google, doanh nghiệp gần như có thể khống chế cả cuộc chơi trong thời gian ngắn nhưng rất đắt giá, khi số lượng người dùng truy cập tăng lên, chỉ số nhận diện thương hiệu sẽ tăng theo.",
  },
  {
    icon: <FiBarChart size={40} color="#2EAFEF" />,
    title: "Cải thiện Return of Investment (ROI)",
    description:
      "Chỉ số ROI càng cao là tín hiệu đáng mừng cho một mô hình kinh doanh hiệu quả. Hàng ngàn khách hàng Gleads đã tối ưu doanh thu bán hàng và chốt đơn thành công thông qua các dự án landing page optimzation, SEO tổng thể, content marketing, Facebook ads. Hãy để Gleads giúp doanh nghiệp tăng trưởng ROI!",
  },
  {
    icon: <BiNetworkChart size={40} color="#2EAFEF" />,
    title: "Kết nối khách hàng tiềm năng, tối ưu lợi nhuận",
    description:
      "Dịch vụ SEO content chuyên biệt giúp xây dựng các nền tảng liên kết cho website, nội dung phù hợp người dùng ở từng giai đoạn, thiết kế chuyên nghiệp giúp tối ưu trải nghiệm khách hàng, chiến dịch quảng cáo tối ưu lợi nhuận nhanh chóng và tiếp cận lượng lớn khách hàng tiềm năng.",
  },
];

const subjects = [
  {
    icon: <BiBriefcaseAlt2 size={40} color="#2EAFEF" />,
    title: "Khởi nghiệp Startups",
  },
  {
    icon: <BiBuildingHouse size={40} color="#2EAFEF" />,
    title: "Doanh nghiệp vừa và nhỏ (SMEs)",
  },
  {
    icon: <BiBuilding size={40} color="#2EAFEF" />,
    title: "Doanh nghiệp lớn",
  },
  {
    icon: <FiMapPin size={40} color="#2EAFEF" />,
    title: "Các chi nhánh hoặc thương hiệu con",
  },
];

/** ========== */
export default function Home({ dataStatic, dataPost }) {
  return (
    <div>
      <section>
        <Banner />
      </section>
      <section className="py-10 pb-0">
        <div className="container mb-10">
          <Title
            as="h2"
            othersClass="text-left mb-6"
            title={{
              content: "Đột phá doanh thu <br/> trong thời đại công nghệ số",
              default_word_color: "text-gleads-pink-600",
              highlight_word_list: ["trong thời đại công nghệ số"],
              highlight_word_colors: "text-gleads-oxfordBlue-700",
            }}
          />
          <p className="mb-6">
            Mục tiêu của doanh nghiệp chỉ có một và sứ mệnh của chúng tôi là đưa
            khách hàng lên top 1. Gleads chuyên cung cấp các giải pháp marketing
            tổng thể tích hợp phương thức quản lý dự án công nghệ hiện đại, phù
            hợp cho mọi doanh nghiệp thuộc đa lĩnh vực và ngành nghề mobile.{" "}
          </p>
          <p className="mb-0">
            Gleads đồng hành cùng tất cả các doanh nghiệp đang cần tìm đối tác
            có “tâm” và “tầm” trong quá trình chuyển đổi số ứng dụng tiếp thị và
            quảng bá dịch vụ. Gleads thấu hiểu các vấn đề nan giải của khách
            hàng, chúng tôi hướng tới các dịch vụ với chi phí hợp lí, lợi nhuận
            tối ưu, phát triển vững bền và nâng cao chất lượng trong mỗi dịch vụ
            khách hàng sử dụng.
          </p>
        </div>
      </section>
      <section className="py-10 pt-0">
        <div className="container">
          <div className="mb-6">
            <Title
              as="h2"
              othersClass="text-left mb-6"
              title={{
                content:
                  "Gleads cung cấp đa dạng dịch vụ <br/> marketing & truyền thông số",
                default_word_color: "text-gleads-oxfordBlue-700",
                highlight_word_list: ["marketing & truyền thông số"],
                highlight_word_colors: "text-gleads-pink-600",
              }}
            />
            <div>
              <p>
                Gleads lên chiến lược, thực hiện, tối ưu và báo cáo đầy đủ cho
                khách hàng. Chúng tôi cung cấp đa dạng các dịch vụ tiếp thị,
                truyền thông số bằng các thuật toán và công nghệ mới nhất. Phạm
                vi dịch vụ Gleads tập trung vào các giải pháp SEO, SEM, PPC
                advertising, communication, web design &amp; development,
                outsourced marketing team cho doanh nghiệp Việt. <br />
                <br />
                Cặp đôi dịch vụ cơ bản cho mỗi doanh nghiệp không thể bỏ lỡ tại
                Gleads: SEO &amp; Google Ads có thể giúp khách hàng dễ dàng leo
                top từ khóa, tăng lượt hiển thị trên các công cụ tìm kiếm
                Google, Bing, Yahoo..., tiếp cận khách hàng tiềm năng và tối ưu
                tỉ lệ chuyển đổi (conversion rate).
                <br />
                <br />
                Khách hàng cần một website diện mạo nổi bật, thiết kế ấn tượng,
                tối ưu trải nghiệm người dùng để chiếm ưu thế trong thị thường
                có nhiều đối thủ cạnh tranh. Gleads cung cấp dịch vụ thiết kế
                nhận diện thương hiệu giúp doanh nghiệp chinh phục thị giác
                người dùng, dễ dàng tương tác với khách hàng, tối ưu hóa doanh
                thu.
                <br />
                <br />
                Hãy khám phá các dịch vụ của Gleads!
              </p>
            </div>
          </div>
          <div className="row">
            <div className="mx-auto">
              <ServiceCatalog services={dataStatic?.services} />
            </div>
          </div>
        </div>
      </section>
      <section className="relative bg-gleads-oxfordBlue-800 pt-15 pb-5">
        <div className="container relative z-10">
          <div className="row">
            <div>
              <Title
                as="h2"
                othersClass="text-left mb-6"
                title={{
                  content: "Chiến dịch Gleads đã và đang làm cho khách hàng",
                  default_word_color: "text-gleads-pink-600",
                  highlight_word_list: ["đã và đang làm cho khách hàng"],
                  highlight_word_colors: "text-white",
                }}
              />
            </div>
            <div>
              <div className="row">
                {campaigns.map((campaign, idx) => {
                  return (
                    <ServiceCard
                      key={`compaign-${idx}`}
                      data={campaign}
                      className="mb-10"
                      inner={{
                        className: "px-6 py-8",
                        iconStyles: "rounded-full bg-gleads-buttonBlue-100 p-3",
                        titleStyles: "text-gleads-pink-700",
                        descriptionStyles: "",
                      }}
                    />
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="bg-white py-10">
        <div className="container">
          <LazyLoad>
            <CountUp data={dataStatic.countUp} />
          </LazyLoad>
        </div>
      </section>
      <section className="bg-gleads-oxfordBlue-100 pt-15">
        <div className="container">
          <Title
            as="h2"
            othersClass="mb-6"
            title={{
              content: "Dịch vụ của Gleads phù hợp với mọi đối tượng",
              default_word_color: "text-gleads-oxfordBlue-700",
              highlight_word_list: ["mọi đối tượng"],
              highlight_word_colors: "text-gleads-pink-600",
            }}
          />
          <div className="row">
            <GleadsSubjects data={subjects} />
            <div className="relative">
              <div className={styles.subjectsImage}>
                <Image
                  src={"/assets/images/home/subjects.png"}
                  alt="subjects"
                  width={638}
                  height={600}
                  unoptimized={true}
                  className="h-full w-full"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="bg-white py-10">
        <div className="container">
          <Title
            as="h2"
            othersClass="mb-6"
            title={{
              content: "Nhận xét của khách hàng",
              default_word_color: "text-gleads-oxfordBlue-700",
            }}
          />
          <Feedback data={dataStatic?.feedbacks} />
        </div>
      </section>

      <section className="overflow-hidden py-10">
        <div className="container">
          <Title title={{ content: "Bài viết liên quan" }} othersClass="mb-2" />
          <div className="row">
            <div className="col-12 px-0">
              <PostSlider posts={dataPost} />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
